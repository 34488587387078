import React from "react"
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import Layout from "../components/layout"
import SEO from "../components/seo"

// Styles
// import "../sass/main.scss"
import styles from "../sass/pages/culture.module.scss"

const Culture = () => {
  const title = useIntl().formatMessage({ id: 'culture' })

  return (<Layout>
    <SEO title={title} />
    <article className="container-small">
      <h2><FormattedMessage id="culture_title" /></h2>
      {/* <p>We started as a completely remote team with just one client.
      <br />After a year we had opened our first office and were growing rapidly.
      <br />After four years, we have gathered a wonderful team of 30 amazing professionals and humans, working for our customers in Uruguay, Argentina, United States and Canada.
      </p> */}
      <p><FormattedMessage id="culture_first_paragraph" values={{
        breakeLine: <br />,
      }} /></p>
      <h3><FormattedMessage id="culture_second_title" /></h3>
      {/* Image */}
      <h3><FormattedMessage id="culture_third_title" /></h3>
      <p><FormattedMessage id="culture_reason_1" /></p>
      <p><FormattedMessage id="culture_reason_2" /></p>
      <p><FormattedMessage id="culture_reason_3" /></p>
      <p><FormattedMessage id="culture_reason_4" /></p>
      <p><FormattedMessage id="culture_reason_5" /></p>
      <p><FormattedMessage id="culture_reason_6" /></p>

      <h3><FormattedMessage id="culture_reason_7" /></h3>
      {/*here goes the map*/}

      <h3><FormattedMessage id="culture_philosophy_title" /></h3>
      <p><FormattedMessage id="culture_philosophy_1" /></p>
      <p><FormattedMessage id="culture_philosophy_2" /></p>
      <p><FormattedMessage id="culture_philosophy_3" /></p>
      <p><FormattedMessage id="culture_philosophy_4" /></p>

      <h3><FormattedMessage id="culture_workwithus_title" values={{breakeLine: <br />}}/></h3>
      <button className="btn btn-white"><FormattedMessage id="culture_workwithus_button" /></button>


      <article className="section-fade">
        <h2><FormattedMessage id="culture" /></h2>
      </article>
    </article>
  </Layout>
  )
}

export default Culture
